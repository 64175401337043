import moment from 'moment';
import React, { useCallback } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  clearSelectedShiftsAction,
  onCopyAction,
  onPasteAction,
  setShiftCodeAction,
} from '../actions/ShiftAction';
import { IShiftCodeState } from '../states/ShiftCodeReducer';
import { IAppState } from '../store';
import { MyProps } from './ShiftComponent';
import ShiftRowComponent from './ShiftRowComponent';
import TripRowComponent from './TripRow';

const MenuItemBox = styled.div<{ color?: string }>`
  border-bottom: 1px solid #ddd;
  background-color: ${(props) => props.color};
  cursor: pointer;
  padding: 2px;
  &:hover {
    opacity: 0.5;
  }
`;

const ShiftCellsContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
`;

const MenuBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ShiftContextMenu = styled(ContextMenu)`
  z-index: 1000;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.479);
  background-color: #fff;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 2px;
`;

const ContextMenuComponent: React.FC<{ shifts: MyProps }> = ({ shifts }) => {
  const dispatch = useDispatch();
  const handleShiftClick = useCallback(
    (e: any, { shiftCode }: { shiftCode: string }) => {
      dispatch(setShiftCodeAction(shiftCode));
    },
    [dispatch],
  );

  const hasWriteAuth = useSelector<IAppState, boolean>((state) =>
    state.permissionsState.some((p) =>
      ['update:shifts', 'create:shifts'].some((s) => s === p),
    ),
  );

  const showTrips = useSelector<IAppState, boolean>(
    (state) => state.showTripInfo,
  );
  const shiftCodeState = useSelector<IAppState, IShiftCodeState>(
    (state) => state.shiftCodeState,
  );

  return (
    <ContextMenuTrigger disable={!hasWriteAuth} id="setShiftCode">
      <ShiftContextMenu
        id="setShiftCode"
        onHide={() => dispatch(clearSelectedShiftsAction())}
      >
        {shiftCodeState.shiftCodes && (
          <MenuBox>
            {shiftCodeState.shiftCodes
              .filter((e) => e !== null)
              .map((shift) => (
                <MenuItem
                  key={shift?.shiftCode || ''}
                  data={{ shiftCode: shift?.shiftCode || '' }}
                  onClick={handleShiftClick}
                >
                  <MenuItemBox color={shift?.backgroundColor}>
                    <div>
                      {shift?.shiftCode}: {shift?.shiftNameEn}
                    </div>
                  </MenuItemBox>
                </MenuItem>
              ))}
          </MenuBox>
        )}
        <MenuItem onClick={() => dispatch(onCopyAction())}>
          <MenuItemBox>
            <div>Copy Selected Cells</div>
          </MenuItemBox>
        </MenuItem>
        <MenuItem onClick={() => dispatch(onPasteAction())}>
          <MenuItemBox>
            <div>Paste into Selected Cell</div>
          </MenuItemBox>
        </MenuItem>
      </ShiftContextMenu>
      <ShiftCellsContainer>
        {shifts.emps.map((data) => {
          return (
            <ShiftRowComponent key={data.empId} empId={data.empId}>
              {showTrips && (
                <TripRowComponent
                  key={data.empId}
                  empId={data.empId}
                  start={moment.utc(shifts.shiftStartDate)}
                  end={moment.utc(shifts.shiftEndDate)}
                />
              )}
            </ShiftRowComponent>
          );
        })}
      </ShiftCellsContainer>
    </ContextMenuTrigger>
  );
};

export default ContextMenuComponent;
