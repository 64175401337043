import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GetTripsAction,
  getTripsAsyncAction,
  getTripCodesAsyncAction,
  PatchTripAction,
  patchTripAsyncAction,
  PostTripAction,
  postTripAsyncAction,
  DeleteTripAction,
  deleteTripAsyncAction,
} from '../actions/TripsAction';
import {
  getTrips,
  getTripCodes,
  patchTrip,
  postTrip,
  deleteTrip,
} from '../services/api-service';
import { PromiseGenericType } from './PromiseGenericType';

function* getTripSaga(action: GetTripsAction) {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof getTrips>> = yield call(
    getTrips,
    action.payload,
  );
  if (!error && data) {
    yield put(
      getTripsAsyncAction.done({ params: action.payload, result: data }),
    );
  } else {
    yield put(getTripsAsyncAction.failed({ error, params: action.payload }));
  }
}

function* patchTripSaga(action: PatchTripAction) {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof patchTrip>> = yield call(
    patchTrip,
    action.payload,
  );
  if (!error && data) {
    yield put(
      patchTripAsyncAction.done({ params: action.payload, result: data }),
    );
  } else {
    yield put(patchTripAsyncAction.failed({ error, params: action.payload }));
  }
}

function* postTripSaga(action: PostTripAction) {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof postTrip>> = yield call(
    postTrip,
    action.payload,
  );
  if (!error && data) {
    yield put(
      postTripAsyncAction.done({ params: action.payload, result: data }),
    );
  } else {
    yield put(postTripAsyncAction.failed({ error, params: action.payload }));
  }
}

function* deleteTripSaga(action: DeleteTripAction) {
  const {
    error,
  }: PromiseGenericType<ReturnType<typeof deleteTrip>> = yield call(
    deleteTrip,
    action.payload,
  );
  if (!error) {
    yield put(deleteTripAsyncAction.done({ params: action.payload }));
  } else {
    yield put(deleteTripAsyncAction.failed({ error, params: action.payload }));
  }
}

function* getTripCodeSaga() {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof getTripCodes>> = yield call(
    getTripCodes,
  );
  if (!error && data) {
    yield put(getTripCodesAsyncAction.done({ result: data }));
  } else {
    yield put(getTripCodesAsyncAction.failed({ error }));
  }
}

export default function* combinedTripSaga() {
  yield takeLatest(getTripsAsyncAction.started, getTripSaga);
  yield takeLatest(getTripCodesAsyncAction.started, getTripCodeSaga);
  yield takeLatest(patchTripAsyncAction.started, patchTripSaga);
  yield takeLatest(postTripAsyncAction.started, postTripSaga);
  yield takeLatest(deleteTripAsyncAction.started, deleteTripSaga);
}
