import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import React from 'react';

const DialogComponent: React.FC<{
  onDialogClose: (cancel: boolean) => void;
  isOpen: boolean;
}> = ({ onDialogClose, isOpen }) => {
  function onClickHandler(cancel: boolean) {
    onDialogClose(cancel);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onDialogClose(true);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This work cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClickHandler(true)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClickHandler(false)}
          color="primary"
          variant="outlined"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
