import React, { useState, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import RedoIcon from '@material-ui/icons/Redo';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import {
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {
  decShiftHistoryIndexAction,
  incShiftHistoryIndexAction,
  postShiftAsyncAction,
  clearHistoryAction,
} from '../actions/ShiftAction';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../store';

const useStyles = makeStyles((theme) => ({
  root: (props: { span: number }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gridColumn: `span ${props.span}`,
  }),
  button: {
    margin: theme.spacing(1),
  },
}));

const EditorComponent: React.FC<{ span: number }> = (props) => {
  const classes = useStyles({ span: props.span });
  const dispatch = useDispatch();

  const [isOpen, setIsopen] = useState(false);
  const [focused, setFocused] = useState<'save' | 'discard' | null>(null);

  const isUpdated = useSelector<IAppState, boolean>((state) =>
    state.currentShiftView.data.some((d) => d.shifts.some((s) => s.isUpdated)),
  );

  const isUndoneable = useSelector<IAppState, boolean>(
    (state) => state.shiftEditHistoryIndex > 0,
  );

  const isCurrent = useSelector<IAppState, boolean>(
    (state) =>
      state.shiftEditHistory.history.length - 1 === state.shiftEditHistoryIndex,
  );

  const hasWriteAuth = useSelector<IAppState, boolean>((state) =>
    state.permissionsState.some((p) =>
      ['update:shifts', 'create:shifts'].some((s) => s === p),
    ),
  );

  const handleDialogClose = useCallback(
    (c: boolean) => {
      setIsopen(false);
      if (c) {
        switch (focused) {
          case 'save':
            dispatch(postShiftAsyncAction.started({}));
            break;
          case 'discard':
            dispatch(clearHistoryAction());
            break;
          default:
            break;
        }
      }
      setFocused(null);
    },
    [dispatch, focused],
  );

  if (!hasWriteAuth) return <></>;
  return (
    <>
      <div className={classes.root}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          startIcon={<SaveIcon />}
          disabled={!isUpdated}
          onClick={() => {
            setIsopen(true);
            setFocused('save');
          }}
        >
          Save
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          startIcon={<DeleteIcon />}
          disabled={!isUpdated}
          onClick={() => {
            setIsopen(true);
            setFocused('discard');
          }}
        >
          Discard
        </Button>
        <Tooltip title="Undo">
          <span>
            <IconButton
              aria-label="undo"
              disabled={!isUndoneable}
              onClick={() => dispatch(decShiftHistoryIndexAction())}
            >
              <UndoIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Redo">
          <span>
            <IconButton
              aria-label="redo"
              disabled={isCurrent}
              onClick={() => dispatch(incShiftHistoryIndexAction())}
            >
              <RedoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsopen(false);
          setFocused(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This work cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditorComponent;
