import axios from 'axios';
import config from '../auth_config.json';
import { IGroup } from '../models/Group';
import { IFilter } from '../models/IFilter';
import { IHoliday } from '../models/IHoliday';
import {
  IShiftCode,
  IShiftData,
  ITrip,
  ITripCode,
  ITripPatchParams,
  ITripPostParams,
} from '../models/Shift';
import store from '../store';
import createAuth0Client from '@auth0/auth0-spa-js';

function createConnection() {
  return axios.create({ baseURL: process.env.REACT_APP_API_SERVER });
}

function createAuthConnection(token?: string) {
  let storedToken = '';
  if (!token) {
    storedToken = store.getState().tokenState;
    if (!storedToken) {
      throw new Error('No auth token set.');
    }
  }
  const ax = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    headers: {
      authorization: `Bearer ${token || storedToken}`,
    },
  });
  return ax;
}

export async function getShifts(params: IFilter) {
  const ax = createConnection();
  try {
    const res = await ax.get<IShiftData>('/shifts', {
      params: { ...params, group: params.group.map((g) => g._id).join(',') },
    });
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getShiftCodes() {
  const ax = createConnection();
  try {
    const res = await ax.get<IShiftCode[]>('/shiftcodes');
    return {
      data: {
        items: res.data,
        count: res.data.length,
      },
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getHolidays() {
  const ax = createConnection();
  try {
    const res = await ax.get<IHoliday[]>('/holidays');
    return {
      data: {
        items: res.data,
        count: res.data.length,
      },
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getGroups() {
  const ax = createConnection();
  try {
    const res = await ax.get<IGroup[]>('/groups');
    return {
      data: {
        items: res.data,
        count: res.data.length,
      },
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function postShifts(shifts: {
  updatedShifts: {
    shiftId: string;
    shiftCodeId: string;
  }[];
  newShifts: {
    empId: string;
    shiftCodeId: string;
    date: string;
  }[];
}) {
  try {
    const ax = createAuthConnection();
    const res = await Promise.all([
      ax.post<string>('/shifts', {
        newShifts: shifts.newShifts,
      }),
      ax.patch<string>('/shifts', {
        updatedShifts: shifts.updatedShifts,
      }),
    ]);
    return {
      data: res.map((s) => s.data),
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getPermissons(token: string) {
  try {
    const ax = createAuthConnection(token);
    const res = await ax.get<{ permissions: string[] }>('/permissions');
    return {
      data: res.data,
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getToken() {
  try {
    const auth0 = await createAuth0Client({
      client_id: config.clientId,
      domain: config.domain,
      audience: config.audience,
      redirect_uri: window.location.origin,
    });
    console.log('token');
    const token = await auth0.getTokenSilently();
    console.log(token);
    return {
      data: token,
      error: null,
    };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getTrips(p: string) {
  try {
    const ax = createConnection();
    const res = await ax.get<ITrip[]>('/trips', { params: { empId: p } });
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function getTripCodes() {
  try {
    const ax = createConnection();
    const res = await ax.get<ITripCode[]>('/tripcodes');
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function patchTrip(p: ITripPatchParams) {
  try {
    const ax = createAuthConnection();
    const res = await ax.patch<ITrip>(`/trips/${p._id}`, { trip: p.trip });
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function postTrip(p: ITripPostParams) {
  try {
    const ax = createAuthConnection();
    const res = await ax.post<ITrip>('trips', { trip: p.trip });
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export async function deleteTrip(id: string) {
  try {
    const ax = createAuthConnection();
    const res = await ax.delete<void>(`trips/${id}`);
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}
