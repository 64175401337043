import { CircularProgress } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SpinnerConteiner = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const LazyContainer: React.FC<{
  loading: boolean;
  children: ReactNode;
}> = ({ loading, children }) => {
  if (loading) {
    return (
      <SpinnerConteiner>
        <CircularProgress />
      </SpinnerConteiner>
    );
  } else {
    return <>{children}</>;
  }
};

export default LazyContainer;
