import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import { HotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getHolidaysAsyncAction } from '../actions/HolidayAction';
import {
  getShiftCodeAsyncAction,
  onCopyAction,
  onPasteAction,
  setCopiedItemsAction,
} from '../actions/ShiftAction';
import { IRow, OnLoadColsPayload } from '../models/Cells';
import { countHolidays } from '../services/holidayChecker';
import { IAppState } from '../store';
import ContextMenuComponent from './ContextMenuComponent';
import CounterColumnComponent from './CounterColumnComponent';
import FilterComponent from './FilterComponent';
import LazyContainer from './LazyContainer';
import ShiftHeaderColumnComponent from './ShiftHeaderColumnComponent';
import ShiftHeaderRowComponent from './ShiftHeaderRowComponent';
import EditorComponent from './EditorComponent';
import { IEmp } from '../models/Shift';

const ShiftContainer = styled.div`
  display: grid;
  height: calc(100vh - 91px);
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: auto auto 1fr;
  min-height: 0;
  min-width: 0;
  border: 1px solid #ddd;
`;

const DeadSpace = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  align-items: end;
`;

const ShiftColHeaderConteiner = styled.div`
  overflow: hidden;
  min-width: 0;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ShiftRowHeaderContainer = styled.div`
  overflow: hidden;
  min-height: 0;
  border-top: 1px solid #ddd;
`;

const ShiftContentsContainer = styled.div`
  overflow: auto;
  scroll-behavior: smooth;
  border-right: 1px solid #ddd;
`;

const ColHeaderCell = styled.div`
  padding: 0 10px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
`;

const ColHeaderCellCorner = styled(ColHeaderCell)`
  grid-row: 2;
`;

const ColHeaderCellTop = styled(ColHeaderCell)`
  grid-row: 1;
  grid-column: span 3;
`;

export type MyProps = {
  shiftStartDate: string;
  shiftEndDate: string;
  emps: IEmp[];
};

const ShiftComponent: React.FC = (props) => {
  const shifts = useSelector<IAppState, MyProps>(
    ({ currentShiftView }) => ({
      shiftStartDate: currentShiftView.shiftStartDate,
      shiftEndDate: currentShiftView.shiftEndDate,
      emps: currentShiftView.data.map((d) => d.emp),
    }),
    (l, r) => {
      return _.isEqual(l, r);
    },
  );

  const isShiftLoaded = useSelector<IAppState, boolean>(
    (state) => state.shiftState.isLoaded,
  );
  const isShiftCodesNeedToBeLoaded = useSelector<IAppState, boolean>(
    (state) =>
      !state.shiftCodeState.isLoaded && !state.shiftCodeState.isLoading,
  );
  const isHolidaysNeedToBeLoaded = useSelector<IAppState, boolean>(
    (state) => !state.holidayState.isLoaded && !state.holidayState.isLoading,
  );
  const dispatch = useDispatch();

  function handleOnScroll(event: React.UIEvent<HTMLDivElement>) {
    const { scrollTop, scrollLeft } = event.currentTarget;
    headerColRef.current?.scrollTo({ top: scrollTop });
    countColRef.current?.scrollTo({ top: scrollTop });
    rowRef.current?.scrollTo({ left: scrollLeft });
  }

  function onCopyHandler() {
    dispatch(onCopyAction());
  }
  function onPasteHandler() {
    dispatch(onPasteAction());
  }
  function onEscapeHandler() {
    dispatch(setCopiedItemsAction([]));
  }

  const headerColRef = useRef<HTMLDivElement>(null);
  const countColRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const getRows = useCallback(() => {
    if (isShiftLoaded) {
      const r: IRow[] = [];
      const mDateStart = moment.utc(shifts.shiftStartDate);
      const mDateEnd = moment.utc(shifts.shiftEndDate);
      for (
        let mCurDate = mDateStart.clone();
        mCurDate.isSameOrBefore(mDateEnd);
        mCurDate.add(1, 'd')
      ) {
        r.push({ date: mCurDate.toISOString() });
      }
      return r;
    }
    return [];
  }, [isShiftLoaded, shifts.shiftEndDate, shifts.shiftStartDate]);

  const getCols = useCallback(() => {
    return [...shifts.emps];
  }, [shifts]);

  useEffect(() => {
    if (isShiftCodesNeedToBeLoaded) {
      dispatch(getShiftCodeAsyncAction.started({}));
    }
    if (isHolidaysNeedToBeLoaded) {
      dispatch(getHolidaysAsyncAction.started({}));
    }
  }, [dispatch, isHolidaysNeedToBeLoaded, isShiftCodesNeedToBeLoaded]);

  function handleOnColLoad({ empId, empName, group }: OnLoadColsPayload) {
    const e = `grid-template-columns: ${empName}px ${group}px`;
    if (headerRef.current) {
      headerRef.current.setAttribute('style', e);
    }
  }

  return (
    <React.Fragment>
      <HotKeys
        keyMap={{
          COPY: ['ctrl+c', 'command+c'],
          PASTE: ['ctrl+v', 'command+v'],
          ESC: 'esc',
        }}
      >
        <HotKeys
          handlers={{
            COPY: onCopyHandler,
            PASTE: onPasteHandler,
            ESC: onEscapeHandler,
          }}
        >
          <ShiftContainer>
            <FilterComponent span={3} />
            <EditorComponent span={3} />
            <LazyContainer
              loading={!(getRows().length && getCols().length && isShiftLoaded)}
            >
              <DeadSpace ref={headerRef}>
                <ColHeaderCellTop>Member</ColHeaderCellTop>
                {/* <ColHeaderCellCorner>Emp ID</ColHeaderCellCorner> */}
                <ColHeaderCellCorner>Name</ColHeaderCellCorner>
                <ColHeaderCellCorner>Group</ColHeaderCellCorner>
              </DeadSpace>
              <ShiftRowHeaderContainer ref={rowRef}>
                <FlexContainer>
                  <ShiftHeaderRowComponent
                    rows={getRows()}
                    begin={moment.utc(shifts.shiftStartDate)}
                    end={moment.utc(shifts.shiftEndDate)}
                  />
                </FlexContainer>
              </ShiftRowHeaderContainer>
              <DeadSpace>
                <ColHeaderCellTop>
                  Dedicated Holidays: {countHolidays()}
                </ColHeaderCellTop>
                <ColHeaderCellCorner>Count of Holidays</ColHeaderCellCorner>
                <ColHeaderCellCorner>Delta</ColHeaderCellCorner>
              </DeadSpace>
              <ShiftColHeaderConteiner ref={headerColRef}>
                <FlexContainer>
                  <ShiftHeaderColumnComponent
                    emps={getCols()}
                    onLoadCols={handleOnColLoad}
                  />
                </FlexContainer>
              </ShiftColHeaderConteiner>
              <ShiftContentsContainer onScrollCapture={handleOnScroll}>
                <FlexContainer>
                  <ContextMenuComponent shifts={shifts} />
                </FlexContainer>
              </ShiftContentsContainer>
              <ShiftColHeaderConteiner ref={countColRef}>
                <CounterColumnComponent
                  emps={getCols()}
                  dedicatedHolidays={countHolidays()}
                />
              </ShiftColHeaderConteiner>
            </LazyContainer>
          </ShiftContainer>
        </HotKeys>
      </HotKeys>
    </React.Fragment>
  );
};

export default ShiftComponent;
