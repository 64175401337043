import actionCreator from './ActionCreator';
import {
  ITrip,
  ITripCode,
  ITripPatchParams,
  ITripPostParams,
} from '../models/Shift';

export const getTripsAsyncAction = actionCreator.async<string, ITrip[]>(
  'GET_TRIPS',
);

export const getTripCodesAsyncAction = actionCreator.async<void, ITripCode[]>(
  'GET_TRIP_CODE',
);

export const patchTripAsyncAction = actionCreator.async<
  ITripPatchParams,
  ITrip
>('PATCH_TRIP');

export const postTripAsyncAction = actionCreator.async<ITripPostParams, ITrip>(
  'POST_TRIP',
);

export const deleteTripAsyncAction = actionCreator.async<string, void>(
  'DELETE_TRIP',
);

export type GetTripsAction = ReturnType<typeof getTripsAsyncAction.started>;
export type PatchTripAction = ReturnType<typeof patchTripAsyncAction.started>;
export type PostTripAction = ReturnType<typeof postTripAsyncAction.started>;
export type DeleteTripAction = ReturnType<typeof deleteTripAsyncAction.started>;
