import React from 'react';
import styled from 'styled-components';

export const ColHeaderCell = styled.div`
  padding: 0 10px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
`;

const ColHeaderCellComponent = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, ref) => <ColHeaderCell {...props} ref={ref} />);

export default ColHeaderCellComponent;
