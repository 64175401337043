import { IFilter } from '../models/IFilter';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  setDateFilterAction,
  setGroupFilterAction,
} from '../actions/FilterAction';
import moment from 'moment';

const initialFilterState: IFilter = {
  start: moment.utc().startOf('M').valueOf(),
  end: moment.utc().endOf('M').valueOf(),
  group: [],
};

export const filterStateReducer = reducerWithInitialState(initialFilterState)
  .case(setDateFilterAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
  .case(setGroupFilterAction, (state, payload) => {
    return {
      ...state,
      group: payload,
    };
  });
