import { IFilter } from '../models/IFilter';
import { IPayload } from '../models/IPayload';
import {
  IShift,
  IShiftCode,
  IShiftData,
  IUpdatedSelection,
} from '../models/Shift';
import actionCreator from './ActionCreator';

export const setSelectedShiftStartAction = actionCreator<IShift>(
  'SET_SELECTED_SHIFT_START',
);
export const setSelectedShiftEndAction = actionCreator<IShift>(
  'SET_SELECTED_SHIFT_END',
);

export const setClickedShiftAction = actionCreator<IShift>('SET_CLICKED_SHIFT');

export const setSelectedShiftsAction = actionCreator<IShift[]>(
  'SET_SELECTED_SHIFTS',
);

export const setUpdatedShiftsAction = actionCreator<IUpdatedSelection[]>(
  'SET_UPDATED_SHIFTS',
);

export const getShiftDataAction = actionCreator.async<IFilter, IShiftData>(
  'GET_SHIFT',
);

export const getShiftCodeAsyncAction = actionCreator.async<
  {},
  IPayload<IShiftCode[]>
>('GET_SHIFT_CODE');

export const clearSelectedShiftsAction = actionCreator('CLEAR_SELECTED_SHIFTS');

export const setShiftCodeAction = actionCreator<string>('SET_SHIFT_CODE');
export const setShowTripAction = actionCreator<boolean>('SET_SHOW_TRIP');
export const onCopyAction = actionCreator('ON_COPY');
export const onPasteAction = actionCreator('ON_PASTE');
export const pasteShiftCodeAction = actionCreator<IShift[]>('PASTE_SHIFT_CODE');
export const setCopiedItemsAction = actionCreator<IShift[]>(
  'SET_COPIED_ACTION',
);

export const shiftHistoryPushAction = actionCreator<IShiftData>(
  'SHIFT_HISTORY_PUSH',
);

export const shiftHistoryNewAction = actionCreator<IShiftData>(
  'SHIFT_HISTORY_NEW',
);

export const incShiftHistoryIndexAction = actionCreator(
  'INC_SHIFT_HISTORY_INDEX',
);

export const clearHistoryAction = actionCreator('CLEAR_HISTORY');

export const resetShiftHistoryIndexAction = actionCreator(
  'RESET_SHIFT_HISTORY_INDEX',
);

export const decShiftHistoryIndexAction = actionCreator(
  'DEC_SHIFT_HISTORY_INDEX',
);

export const setShiftHistoryIndexAction = actionCreator<number>(
  'SET_SHIFT_HISTORY_INDEX',
);

export const setCurrentViewAction = actionCreator<IShiftData>(
  'SET_CURRENT_VIEW',
);

export const setShiftAsCalendarAction = actionCreator<string>(
  'SET_SHIFT_AS_CALENDAR',
);

export const reloadShiftDataAction = actionCreator('RELOAD_SHIFT');

export const postShiftAsyncAction = actionCreator.async<{}, {}>('POST_SHIFT');

export type GetShiftDataAction = ReturnType<typeof getShiftDataAction.started>;
export type SetSelectedShiftEndAction = ReturnType<
  typeof setSelectedShiftEndAction
>;

export type SetSelectedShiftStartAction = ReturnType<
  typeof setSelectedShiftStartAction
>;

export type SetShiftCodeAction = ReturnType<typeof setShiftCodeAction>;
export type SetClickedShiftAction = ReturnType<typeof setClickedShiftAction>;
export type PasteShiftCodeAction = ReturnType<typeof pasteShiftCodeAction>;
export type SetShiftAsCalendarAction = ReturnType<
  typeof setShiftAsCalendarAction
>;
