export interface IStatus {
  count?: number;
  isLoading: boolean;
  isLoaded: boolean;
  error?: {};
}

export const initialStatus: IStatus = {
  isLoaded: false,
  isLoading: false,
  error: undefined,
};

export const startedStatus: IStatus = {
  isLoaded: false,
  isLoading: true,
  error: undefined,
};

export const doneStatus: IStatus = {
  isLoaded: true,
  isLoading: false,
  error: undefined,
};

export function errorStatus(error: {}): IStatus {
  return {
    isLoaded: true,
    isLoading: false,
    error,
  };
}
