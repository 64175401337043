import actionCreator from './ActionCreator';
import { IFilter } from '../models/IFilter';
import { IGroup } from '../models/Group';

export const setFilterAction = actionCreator<IFilter>('SET_FILTER');
export const setDateFilterAction = actionCreator<{
  start: number;
  end: number;
}>('SET_DATE_FILTER');
export type SetFilterAction = ReturnType<typeof setFilterAction>;

export const setGroupFilterAction = actionCreator<IGroup[]>('SET_GROUP_FILTER');
