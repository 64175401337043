import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IEmp } from '../models/Shift';
import { IAppState } from '../store';
import ColHeaderCellComponent from './ColHeaderCellComponent';

const CounterColumnContainer = styled.div<{
  showTrip: boolean;
}>`
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: ${(props) => (props.showTrip ? '71px' : '36px')};
  border-right: 1px solid #ddd;
`;
type IProp = {
  empId: string;
  holidayCount: number;
}[];

const CounterColumnComponent: React.FC<{
  emps: IEmp[];
  dedicatedHolidays: number;
}> = ({ emps, dedicatedHolidays }) => {
  const showTrip = useSelector<IAppState, boolean>(
    (state) => state.showTripInfo,
  );

  return (
    <CounterColumnContainer showTrip={showTrip}>
      {emps.map((col) => (
        <HolidayCounterCellComponent
          key={col.empId}
          empId={col.empId}
          dedicatedHolidays={dedicatedHolidays}
        />
      ))}
    </CounterColumnContainer>
  );
};

export default CounterColumnComponent;

const HolidayCounterCellComponent: React.FC<{
  empId: string;
  dedicatedHolidays: number;
}> = ({ empId, dedicatedHolidays }) => {
  const holidays = useSelector<IAppState, number>(({ currentShiftView }) => {
    const data = currentShiftView.data.find((e) => e.emp.empId === empId);
    return (
      data?.shifts.filter((s) =>
        s.newShift ? s.newShift.isHoliday : s.shift?.isHoliday,
      ).length || 0
    );
  });
  return (
    <>
      <ColHeaderCellComponent>{holidays.toString()}</ColHeaderCellComponent>
      <ColHeaderCellComponent>
        {(holidays - dedicatedHolidays).toString()}
      </ColHeaderCellComponent>
    </>
  );
};
