import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from '../store';
import { setClickedShiftAction } from '../actions/ShiftAction';
import { IShift } from '../models/Shift';

const ShiftCell = styled.div<{
  color: string;
  isSelected: boolean;
  isUpdated: boolean;
  isCopied: boolean;
}>`
  text-align: center;
  background-color: ${(props) => props.color};
  border: solid
    ${(props) =>
      props.isCopied
        ? 'rgb(255, 159, 42)'
        : props.isSelected
        ? 'rgb(42, 159, 255)'
        : props.isUpdated
        ? 'rgb(255, 42, 42)'
        : '#ddd'};
  border-width: ${(props) =>
    props.isSelected || props.isUpdated || props.isCopied ? '2px' : '0.5px'};
  color: ${(props) => (props.isUpdated ? 'rgb(255, 42, 42)' : '')};
  font-weight: ${(props) => (props.isUpdated ? 'bold' : '')};
  font-size: 14px;
  border-radius: ${(props) =>
    props.isSelected || props.isUpdated || props.isCopied ? 0 : '5px'};
  display: flex;
  grid-row: 1;
`;

const ShiftCellComponent: React.FC<{
  value: IShift;
}> = ({ value }) => {
  const isSelected = useSelector<IAppState, boolean>((state) =>
    state.selectedShifts.some((s) => s._id === value._id),
  );

  const isCopied = useSelector<IAppState, boolean>((state) =>
    state.copiedShifts.some((s) => s._id === value._id),
  );

  const hasWriteAuth = useSelector<IAppState, boolean>((state) =>
    state.permissionsState.some((p) =>
      ['update:shifts', 'create:shifts'].some((s) => s === p),
    ),
  );

  const dispatch = useDispatch();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.button === 0 && hasWriteAuth) {
        dispatch(setClickedShiftAction(value));
      }
    },
    [dispatch, hasWriteAuth, value],
  );
  return (
    <>
      <ShiftCell
        onMouseDown={handleClick}
        isSelected={isSelected}
        isCopied={isCopied}
        isUpdated={value.isUpdated || false}
        color={
          value.newShift?.backgroundColor || value.shift?.backgroundColor || ''
        }
      >
        <div style={{ margin: 'auto' }}>
          {value.newShift?.shiftCode || value.shift?.shiftCode || '-'}
        </div>
      </ShiftCell>
    </>
  );
};

export default ShiftCellComponent;
