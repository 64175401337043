import actionCreator from './ActionCreator';

export const getTokenAsyncAction = actionCreator.async<void, string>(
  'GET_TOKEN',
);
export const getPermissionsAsyncAction = actionCreator.async<string, string[]>(
  'GET_PERMISSIONS',
);

export type GetPermissionAsyncAction = ReturnType<
  typeof getPermissionsAsyncAction.started
>;
