import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useAuth0 } from '../ReactAuth0Provider';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../store';
import { getPermissionsAsyncAction } from '../actions/TokenActions';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

const Footer = styled.div`
  text-align: center;
`;

export const HeaderComponent: React.FC = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = !!anchorEl;
  const menuId = 'primary-search-account-menu';

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogout = () => {
    logout();
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
    user,
  } = useAuth0();
  const dispatch = useDispatch();
  const token = useSelector<IAppState, string>((state) => state.tokenState);

  useEffect(() => {
    if (isAuthenticated && !token) {
      getTokenSilently().then((s: any) => {
        dispatch(getPermissionsAsyncAction.started(s));
      });
    }
  });
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            Shift Manager
          </Typography>
          {!isAuthenticated && (
            <Button color="inherit" onClick={() => loginWithRedirect({})}>
              Log in
            </Button>
          )}
          {isAuthenticated && (
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Avatar src={user?.picture} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleOnLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default withRouter(HeaderComponent);

export const FooterComponent: React.FC = (props) => {
  return <Footer>&copy; {moment.utc().year().toString()} Hiromi Kohara</Footer>;
};
