import {
  AppBar,
  Card,
  CardContent,
  Dialog,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTripAsyncAction,
  getTripCodesAsyncAction,
  getTripsAsyncAction,
  patchTripAsyncAction,
  postTripAsyncAction,
} from '../actions/TripsAction';
import {
  IEmp,
  ITrip,
  ITripCode,
  ITripPatchParams,
  ITripPostParams,
} from '../models/Shift';
import { ITripCodeState, ITripState } from '../states/TripReducer';
import { IAppState } from '../store';
import DateRangeComponent from './DateRangeComponent';
import DialogComponent from './DialogComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pos: {
    marginBottom: 12,
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
  },
  iconSpacing: {
    margin: theme.spacing(1),
  },
  floatIcon: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(1),
    zIndex: 100,
  },
  appBar: {
    position: 'relative',
  },
  dialog: {
    overflow: 'visible',
  },
  formControl: {
    minWidth: 120,
    marginBottom: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TripComponent: React.FC<{
  emp: IEmp;
  open: boolean;
  onClose: () => void;
}> = ({ emp, open, onClose, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">
            Add/Edit Trip Info: {emp.empName}
          </Typography>
        </Toolbar>
      </AppBar>
      <TripListComponent emp={emp} />
    </Dialog>
  );
};

const TripListComponent: React.FC<{ emp: IEmp }> = ({ emp, ...props }) => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<ITrip | null>(null);
  const [newTrip, setNewTrip] = useState<ITrip | null>(null);
  const curEmpId = useRef('');
  const dispatch = useDispatch();
  const tripState = useSelector<IAppState, ITripState>(
    (state) => state.tripState,
  );
  const tripCodeState = useSelector<IAppState, ITripCodeState>(
    (state) => state.tripCodeState,
  );

  const onChangeHandler = useCallback((i: ITrip) => {
    setSelectedRow(i);
  }, []);

  const onAddHandler = useCallback(() => {
    const newTrip: ITrip = {
      _id: 'new',
      emp,
      trip: tripCodeState.tripCodes[0],
      tripDateFrom: moment().utc().toISOString(),
      tripDateTo: moment().utc().toISOString(),
    };
    setNewTrip(newTrip);
    setSelectedRow(newTrip);
  }, [emp, tripCodeState.tripCodes]);

  const onSaveHandler = useCallback(
    (i: ITripPatchParams) => {
      if (i._id === 'new') {
        const t = i as ITripPostParams;
        dispatch(
          postTripAsyncAction.started({
            trip: { ...t.trip, emp: emp._id },
          }),
        );
      } else {
        dispatch(patchTripAsyncAction.started(i));
      }
      setSelectedRow(null);
      setNewTrip(null);
    },
    [dispatch, emp._id],
  );

  const onDeleteHandler = useCallback(
    (id: string) => {
      dispatch(deleteTripAsyncAction.started(id));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      (!tripState.isLoaded && !tripState.isLoading) ||
      curEmpId.current !== emp._id
    ) {
      curEmpId.current = emp._id;
      dispatch(getTripsAsyncAction.started(emp._id));
    }
  }, [dispatch, emp._id, tripState.isLoaded, tripState.isLoading]);

  useEffect(() => {
    if (
      (!tripCodeState.isLoaded && !tripCodeState.isLoading) ||
      curEmpId.current !== emp._id
    ) {
      dispatch(getTripCodesAsyncAction.started());
    }
  }, [dispatch, emp._id, tripCodeState.isLoaded, tripCodeState.isLoading]);
  return (
    <div>
      {newTrip && (
        <TripSelectorComponent
          selectedTripCode={newTrip.trip}
          disabled={newTrip._id !== selectedRow?._id}
          editButtonDisabled={newTrip._id !== selectedRow?._id && !!selectedRow}
          isEditing={newTrip._id === selectedRow?._id}
          trip={newTrip}
          isNew
          key={newTrip._id}
          onEditStart={onChangeHandler}
          tripCodes={tripCodeState.tripCodes}
          onSave={onSaveHandler}
          onDiscard={() => {
            setSelectedRow(null);
            setNewTrip(null);
          }}
        />
      )}
      {tripState.trips.map((i) => (
        <TripSelectorComponent
          selectedTripCode={i.trip}
          disabled={i._id !== selectedRow?._id}
          editButtonDisabled={i._id !== selectedRow?._id && !!selectedRow}
          isEditing={i._id === selectedRow?._id}
          onDiscard={() => setSelectedRow(null)}
          trip={i}
          key={i._id}
          onEditStart={onChangeHandler}
          tripCodes={tripCodeState.tripCodes}
          onSave={onSaveHandler}
          onDelete={onDeleteHandler}
        />
      ))}
      <Fab color="primary" className={classes.floatIcon} onClick={onAddHandler}>
        <AddIcon />
      </Fab>
    </div>
  );
};

const TripSelectorComponent: React.FC<{
  tripCodes: ITripCode[];
  trip: ITrip;
  disabled: boolean;
  selectedTripCode: ITripCode | null;
  editButtonDisabled: boolean;
  onSave: (t: ITripPatchParams) => void;
  onDiscard: () => void;
  onEditStart: (v: ITrip) => void;
  isEditing: boolean;
  isNew?: boolean;
  onDelete?: (id: string) => void;
}> = ({
  isEditing,
  tripCodes,
  disabled,
  trip,
  onSave,
  onDiscard,
  onEditStart,
  editButtonDisabled,
  isNew = false,
  onDelete,
  ...props
}) => {
  const classes = useStyles();

  const [selectedTripCode, setSelectedTripCode] = useState<ITripCode | null>(
    props.selectedTripCode,
  );

  const [tripDateFrom, setTripDateFrom] = useState<Moment>(
    moment.utc(trip.tripDateFrom),
  );
  const [tripDateTo, setTripDateTo] = useState<Moment>(
    moment.utc(trip.tripDateTo),
  );

  const [open, setOpen] = useState(false);

  const [focused, setFocused] = useState<'delete' | 'discard' | 'save' | null>(
    null,
  );

  const onDateFixedHandler = useCallback(
    (final: { startDate: Moment; endDate: Moment }) => {
      setTripDateFrom(final.startDate);
      setTripDateTo(final.endDate);
    },
    [],
  );

  const onChangeHandler = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const v = event.target.value as string;
      const so = tripCodes.find((tc) => tc._id === v);
      if (so) {
        setSelectedTripCode(so);
      }
    },
    [tripCodes],
  );

  const onSaveHandler = useCallback(() => {
    setOpen(true);
    setFocused('save');
  }, []);

  const onDiscardHandler = useCallback(() => {
    setOpen(true);
    setFocused('discard');
  }, []);

  const onDeleteHandler = useCallback(() => {
    setOpen(true);
    setFocused('delete');
  }, []);

  const onDialogCloseHandler = useCallback(
    (cancel: boolean) => {
      if (!cancel) {
        switch (focused) {
          case 'delete':
            onDelete && onDelete(trip._id);
            break;
          case 'discard':
            setSelectedTripCode(props.selectedTripCode);
            setTripDateFrom(moment.utc(trip.tripDateFrom));
            setTripDateTo(moment.utc(trip.tripDateTo));
            onDiscard();
            break;
          case 'save':
            const param: ITripPatchParams = {
              _id: trip._id,
              trip: {
                trip: selectedTripCode?._id,
                tripDateFrom: tripDateFrom,
                tripDateTo: tripDateTo,
              },
            };
            onSave(param);
            break;
          default:
            break;
        }
      }
      setFocused(null);
      setOpen(false);
    },
    [
      focused,
      onDelete,
      onDiscard,
      onSave,
      props.selectedTripCode,
      selectedTripCode,
      trip._id,
      trip.tripDateFrom,
      trip.tripDateTo,
      tripDateFrom,
      tripDateTo,
    ],
  );

  return (
    <>
      <Card className={classes.root} key={trip._id}>
        <CardContent>
          <FormControl className={classes.formControl} disabled={disabled}>
            <InputLabel>Destination</InputLabel>
            <Select value={selectedTripCode?._id} onChange={onChangeHandler}>
              {tripCodes.map((tc) => (
                <MenuItem key={tc._id} value={tc._id}>
                  {tc.tripCode}: {tc.tripDestination}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.flex}>
            <DateRangeComponent
              endDate={tripDateTo}
              startDate={tripDateFrom}
              onDateFixed={onDateFixedHandler}
              startDateLabel="From"
              endDateLabel="To"
              disabled={disabled}
              fromDateId={`${trip._id}-from`}
              toDateId={`${trip._id}-to`}
            />
          </div>
        </CardContent>
        <Fab
          color="primary"
          size="small"
          className={classes.iconSpacing}
          onClick={() => (isEditing ? onSaveHandler() : onEditStart(trip))}
          disabled={editButtonDisabled}
        >
          {isEditing ? <SaveIcon /> : <EditIcon />}
        </Fab>
        <Fab
          color="secondary"
          size="small"
          className={classes.iconSpacing}
          onClick={isEditing ? onDiscardHandler : onDeleteHandler}
        >
          {isEditing ? <ClearIcon /> : <DeleteIcon />}
        </Fab>
      </Card>
      <DialogComponent isOpen={open} onDialogClose={onDialogCloseHandler} />
    </>
  );
};

export default TripComponent;
