import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { createRef, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setShiftAsCalendarAction } from '../actions/ShiftAction';
import { OnLoadCols } from '../models/Cells';
import { IEmp } from '../models/Shift';
import { IAppState } from '../store';
import ColHeaderCellComponent from './ColHeaderCellComponent';
import TripComponent from './TripComponent';

const ColHeaderContainer = styled.div<{
  showTrip: boolean;
  cols: number;
}>`
  display: grid;
  text-align: left;
  grid-template-columns: repeat(${(props) => props.cols}, auto);
  grid-auto-rows: ${(props) => (props.showTrip ? '71px' : '36px')};
  border-right: 1px solid #ddd;
`;

const FlexBox = styled.div`
  display: flex;
`;

const ColHeaderText = styled.div`
  flex-grow: 1;
`;

const ShiftHeaderColumnComponent: React.FC<{
  emps: IEmp[];
  onLoadCols: OnLoadCols;
}> = ({ emps, onLoadCols }) => {
  const empIdRef = createRef<HTMLDivElement>();
  const empNameRef = createRef<HTMLDivElement>();
  const groupRef = createRef<HTMLDivElement>();

  const hasWriteAuth = useSelector<IAppState, boolean>((state) =>
    state.permissionsState.some((p) =>
      ['update:shiftss', 'create:shifts'].some((s) => s === p),
    ),
  );

  const showTrip = useSelector<IAppState, boolean>(
    (state) => state.showTripInfo,
  );

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [empIdOnMenu, setEmpIdOnMenu] = useState<IEmp | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    emp: IEmp,
  ) => {
    setAnchorEl(event.currentTarget);
    setEmpIdOnMenu(emp);
  };

  const handleSetShiftAsCalendarHandler = () => {
    empIdOnMenu && dispatch(setShiftAsCalendarAction(empIdOnMenu.empId));
    handleClose();
  };

  const handleOpenTripComponentOpen = useCallback(() => {
    empIdOnMenu && setDialogOpen(true);
    handleClose();
  }, [empIdOnMenu]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDialogCloseHandler = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    if (empNameRef.current && groupRef.current) {
      onLoadCols({
        empId: empIdRef?.current?.clientWidth || 0,
        empName: empNameRef.current.clientWidth,
        group: groupRef.current.clientWidth,
      });
    }
  }, [empIdRef, empNameRef, groupRef, onLoadCols]);
  return (
    <>
      <ColHeaderContainer showTrip={showTrip} cols={2}>
        {emps.map((emp) => {
          return (
            <React.Fragment key={emp.empId}>
              <ColHeaderCellComponent ref={empNameRef}>
                <FlexBox>
                  <ColHeaderText>{emp.empName}</ColHeaderText>
                  {hasWriteAuth && (
                    <IconButton onClick={(e) => handleClick(e, emp)}>
                      <MoreHorizIcon />
                    </IconButton>
                  )}
                </FlexBox>
              </ColHeaderCellComponent>
              <ColHeaderCellComponent ref={groupRef}>
                {emp.group.groupName}
              </ColHeaderCellComponent>
            </React.Fragment>
          );
        })}
        {hasWriteAuth && (
          <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
            <MenuItem onClick={handleSetShiftAsCalendarHandler}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText>Set shift as calendar</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleOpenTripComponentOpen}>
              <ListItemIcon>
                <CardTravelIcon />
              </ListItemIcon>
              <ListItemText>Edit/Add Trip Info</ListItemText>
            </MenuItem>
          </Menu>
        )}
      </ColHeaderContainer>
      {empIdOnMenu && (
        <TripComponent
          emp={empIdOnMenu}
          onClose={onDialogCloseHandler}
          open={dialogOpen}
        />
      )}
    </>
  );
};

export default ShiftHeaderColumnComponent;
