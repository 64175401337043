import { fork } from 'redux-saga/effects';
import combinedAuthSaga from './authSaga';
import combinedErrorSaga from './errorSaga';
import combinedGroupSaga from './groupSaga';
import combinedHolidaySaga from './holidaySaga';
import combinedShiftCodeSaga from './shiftCodeSaga';
import combinedShiftSaga from './shiftDataSaga';
import combinedTripSaga from './tripSaga';

export default function* rootSaga() {
  yield fork(combinedShiftSaga);
  yield fork(combinedGroupSaga);
  yield fork(combinedShiftCodeSaga);
  yield fork(combinedHolidaySaga);
  yield fork(combinedAuthSaga);
  yield fork(combinedErrorSaga);
  yield fork(combinedTripSaga);
}
