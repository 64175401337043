import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getPermissionsAsyncAction,
  getTokenAsyncAction,
  GetPermissionAsyncAction,
} from '../actions/TokenActions';
import { getPermissons, getToken } from '../services/api-service';
import { PromiseGenericType } from './PromiseGenericType';

function* getTokenSaga() {
  const {
    error,
    data,
  }: PromiseGenericType<ReturnType<typeof getToken>> = yield call(getToken);
  if (!error && data) {
    yield put(getTokenAsyncAction.done({ result: data }));
  } else {
    yield put(
      getTokenAsyncAction.failed({ error: error || 'Un-caught error' }),
    );
  }
}

function* getPermissionsSaga(p: GetPermissionAsyncAction) {
  const {
    error,
    data,
  }: PromiseGenericType<ReturnType<typeof getPermissons>> = yield call(
    getPermissons,
    p.payload,
  );
  if (!error && data) {
    yield put(
      getPermissionsAsyncAction.done({
        result: data.permissions,
        params: p.payload,
      }),
    );
  } else {
    yield put(
      getPermissionsAsyncAction.failed({
        error: error || 'Un-caught error',
        params: p.payload,
      }),
    );
  }
}

export default function* combinedAuthSaga() {
  yield takeLatest(getTokenAsyncAction.started, getTokenSaga);
  yield takeLatest(getPermissionsAsyncAction.started, getPermissionsSaga);
}
