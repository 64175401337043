import { call, put, takeLatest } from 'redux-saga/effects';
import { getGroupAsyncAction } from '../actions/GroupAction';
import { getGroups } from '../services/api-service';
import { PromiseGenericType } from './PromiseGenericType';

function* getGroupDataSaga() {
  const {
    error,
    data,
  }: PromiseGenericType<ReturnType<typeof getGroups>> = yield call(getGroups);
  if (!error && data) {
    yield put(
      getGroupAsyncAction.done({
        params: {},
        result: data,
      }),
    );
  } else {
    yield put(
      getGroupAsyncAction.failed({
        error: error || 'Un-caught error',
        params: {},
      }),
    );
  }
}

export default function* combinedGroupSaga() {
  yield takeLatest(getGroupAsyncAction.started, getGroupDataSaga);
}
