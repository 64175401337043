import {
  IStatus,
  initialStatus,
  startedStatus,
  doneStatus,
  errorStatus,
} from './IStatus';
import { IGroup } from '../models/Group';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getGroupAsyncAction } from '../actions/GroupAction';

export interface IGroupState extends IStatus {
  groups: IGroup[];
}

const initialGroupState: IGroupState = {
  ...initialStatus,
  groups: [],
};

export const groupStateReducer = reducerWithInitialState(initialGroupState)
  .case(getGroupAsyncAction.started, (state, _payload) => {
    return {
      ...state,
      ...startedStatus,
    };
  })
  .case(getGroupAsyncAction.done, (_state, payload) => {
    return {
      ...doneStatus,
      count: payload.result.count,
      groups: payload.result.items,
    };
  })
  .case(getGroupAsyncAction.failed, (state, payload) => {
    return {
      ...state,
      ...errorStatus(payload.error),
    };
  });
