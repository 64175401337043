import moment, { Moment } from 'moment';
import store from '../store';

export function isMitacHoliday(date: Moment) {
  const holidays = store.getState().holidayState.holidays;
  return (
    date.day() === 0 ||
    date.day() === 6 ||
    holidays.some(
      (h) => h.isMitacHoliday && moment.utc(h.date).isSame(date, 'd'),
    )
  );
}

export function isMhiHoliday(date: Moment) {
  const holidays = store.getState().holidayState.holidays;
  return (
    date.day() === 0 ||
    date.day() === 6 ||
    holidays.some((h) => h.isMhiHoliday && moment.utc(h.date).isSame(date, 'd'))
  );
}

export function countHolidays() {
  const {
    shiftEndDate,
    shiftStartDate,
  } = store.getState().shiftState.shiftData;
  let i = 0;
  for (
    let d = moment.utc(shiftStartDate);
    d.isSameOrBefore(moment.utc(shiftEndDate));
    d.add(1, 'd')
  ) {
    if (isMitacHoliday(d)) {
      i++;
    }
  }
  return i;
}
