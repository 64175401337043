import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { clearErrorAction, setErrorAction } from '../actions/ErrorAction';

export interface IErrorState {
  error?: {};
}

const initialErrorState: IErrorState = {
  error: undefined,
};

export const errorReducer = reducerWithInitialState(initialErrorState)
  .case(setErrorAction, (_state, payload) => ({ error: payload }))
  .case(clearErrorAction, () => ({ error: undefined }));
