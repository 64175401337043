import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { IFilter } from './models/IFilter';
import { IShift, IShiftData } from './models/Shift';
import rootSaga from './sagas/rootSaga';
import { errorReducer, IErrorState } from './states/ErrorState';
import { filterStateReducer } from './states/FIlterState';
import { groupStateReducer, IGroupState } from './states/GroupReducer';
import { holidaysReducer, IHolidayState } from './states/HolidaysReducer';
import { keyReducer } from './states/KeyReducer';
import { IShiftCodeState, shiftCodeReducer } from './states/ShiftCodeReducer';
import {
  copiedShiftReducer,
  currentShiftViewReducer,
  IShiftDataState,
  IShiftEditHistory,
  selectedShiftsReducer,
  shiftDataReducer,
  shiftEditHistoryIndexReducer,
  shiftEditHistoryReducer,
  shiftSelectionReducer,
  showTripInfoReducer,
} from './states/ShiftReducer';
import { permissionsReducer, tokenReducer } from './states/TokenReducer';
import {
  ITripCodeState,
  ITripState,
  tripCodeReducer,
  tripStateReducer,
} from './states/TripReducer';

export interface IAppState {
  keyState: { keyShift: boolean; keyCtrl: boolean };
  selectedShiftRange: { start?: IShift; end?: IShift };
  shiftState: IShiftDataState;
  selectedShifts: IShift[];
  showTripInfo: boolean;
  copiedShifts: IShift[];
  groupState: IGroupState;
  shiftCodeState: IShiftCodeState;
  holidayState: IHolidayState;
  shiftEditHistory: IShiftEditHistory;
  shiftEditHistoryIndex: number;
  currentShiftView: IShiftData;
  filterState: IFilter;
  tokenState: string;
  permissionsState: string[];
  errorState: IErrorState;
  tripState: ITripState;
  tripCodeState: ITripCodeState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
let mw = applyMiddleware(sagaMiddleware);
// if (process.env.NODE_ENV === 'development') {
mw = composeEnhancers(applyMiddleware(sagaMiddleware));
// }

const persistReducers = persistReducer<IAppState>(
  { key: 'root', storage, whitelist: ['filterState'] },
  combineReducers<IAppState>({
    keyState: keyReducer,
    selectedShiftRange: shiftSelectionReducer,
    shiftState: shiftDataReducer,
    selectedShifts: selectedShiftsReducer,
    showTripInfo: showTripInfoReducer,
    copiedShifts: copiedShiftReducer,
    groupState: groupStateReducer,
    shiftCodeState: shiftCodeReducer,
    holidayState: holidaysReducer,
    shiftEditHistory: shiftEditHistoryReducer,
    shiftEditHistoryIndex: shiftEditHistoryIndexReducer,
    currentShiftView: currentShiftViewReducer,
    filterState: filterStateReducer,
    permissionsState: permissionsReducer,
    tokenState: tokenReducer,
    errorState: errorReducer,
    tripState: tripStateReducer,
    tripCodeState: tripCodeReducer,
  }),
);

const store = createStore(persistReducers, mw);
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default store;
