import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  getTokenAsyncAction,
  getPermissionsAsyncAction,
} from '../actions/TokenActions';

const initialPermissionsReducer: string[] = [];
export const tokenReducer = reducerWithInitialState('')
  .case(getTokenAsyncAction.started, () => '')
  .case(getTokenAsyncAction.done, (_state, payload) => payload.result)
  .case(getTokenAsyncAction.failed, () => '')
  .case(getPermissionsAsyncAction.started, (_state, payload) => payload);
export const permissionsReducer = reducerWithInitialState(
  initialPermissionsReducer,
)
  .case(getPermissionsAsyncAction.started, () => [])
  .case(getPermissionsAsyncAction.done, (_state, payload) => payload.result)
  .case(getPermissionsAsyncAction.failed, () => []);
