import React from 'react';
import styled from 'styled-components';
import {
  HeaderComponent,
  FooterComponent,
} from '../components/HeaderComponents';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../store';
import { IErrorState } from '../states/ErrorState';
import { clearErrorAction } from '../actions/ErrorAction';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ContainerGrid = styled.div`
  grid-template-rows: auto minmax(auto, 1fr) 25px;
  display: grid;
  height: 100vh;
`;

const Container = styled.div`
  overflow: auto;
`;

const MainContainer: React.FC = (props) => {
  const errorState = useSelector<IAppState, IErrorState>(
    (state) => state.errorState,
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearErrorAction());
  };

  return (
    <ContainerGrid>
      <HeaderComponent />
      <Container>{props.children}</Container>
      <FooterComponent />
      <Snackbar
        open={!!errorState.error}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorState?.error?.toString()}
        </Alert>
      </Snackbar>
    </ContainerGrid>
  );
};

export default MainContainer;
