import {
  createStyles,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ArrowForward, CalendarToday } from '@material-ui/icons';
import { Moment } from 'moment';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  DayPickerRangeController,
  DayPickerRangeControllerShape,
} from 'react-dates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    middle: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    datePickerContainer: (props: { top: number; left: number }) => ({
      position: 'fixed',
      zIndex: 10,
      top: `${props.top}px`,
      left: `${props.left}px`,
    }),
    textField: {
      width: '18ch',
      cursor: 'pointer',
      '& > *': {
        cursor: 'pointer',
      },
    },
  }),
);

const DateRangeComponent: React.FC<{
  startDate: Moment | null;
  endDate: Moment | null;
  endDateLabel?: string;
  startDateLabel?: string;
  isOutsideRange?: () => boolean;
  disabled?: boolean;
  fromDateId: string;
  toDateId: string;
  onDateFixed: (final: { startDate: Moment; endDate: Moment }) => void;
}> = ({
  endDateLabel = 'End Date',
  startDateLabel = 'Start Date',
  fromDateId,
  toDateId,
  isOutsideRange = () => false,
  disabled = false,
  onDateFixed,
  ...props
}) => {
  const classes = useStyles({ top: 90, left: 90 });

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate',
  );
  const [startDate, setStartDate] = useState<Moment | null>(props.startDate);
  const [endDate, setEndDate] = useState<Moment | null>(props.endDate);
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const dateStartRef = useRef<HTMLDivElement>(null);
  const dateEndRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.endDate, props.startDate]);

  const handleShowDatePicker = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      focus: 'startDate' | 'endDate',
    ) => {
      event.preventDefault();
      const current =
        focus === 'startDate' ? dateStartRef.current : dateEndRef.current;
      if (current) {
        const { left, height, top } = current.getBoundingClientRect();
        const lDiff = window.innerWidth - left;
        const tDiff = window.innerHeight - top - height;
        const l = lDiff >= 619 ? left : window.innerWidth - 619;
        const t = tDiff >= 332 ? top + height : top - 332;
        setLeft(l);
        setTop(t);
      }
      setFocusedInput(focus);
      setOpen(true);
    },
    [],
  );

  const onMouseDownHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
    },
    [],
  );

  const onDateChangedHandler = useCallback(
    (arg: { startDate: Moment | null; endDate: Moment | null }) => {
      setStartDate(arg.startDate);
      setEndDate(arg.endDate);
    },
    [],
  );

  const onDateCloseHandler = useCallback(
    (final: { startDate: Moment; endDate: Moment }) => {
      setFocusedInput('startDate');
      setOpen(false);
      onDateFixed(final);
    },
    [onDateFixed],
  );

  const onFocusChangeHandler = useCallback(
    (arg: 'startDate' | 'endDate' | null) => {
      if (arg) {
        setFocusedInput(arg);
      }
    },
    [],
  );

  const handleOutsideClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <FormControl ref={dateStartRef}>
          <InputLabel htmlFor={fromDateId}>{startDateLabel}</InputLabel>
          <Input
            id={fromDateId}
            type="text"
            readOnly
            disabled={disabled}
            className={classes.textField}
            value={startDate ? startDate.format('L') : ''}
            onChange={handleChange}
            onClick={(e) => !disabled && handleShowDatePicker(e, 'startDate')}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="pick start date from calendar"
                  onMouseDown={onMouseDownHandler}
                >
                  <CalendarToday />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl>
          <IconButton disabled>
            <ArrowForward />
          </IconButton>
        </FormControl>
        <FormControl ref={dateEndRef}>
          <InputLabel htmlFor={toDateId}>{endDateLabel}</InputLabel>
          <Input
            id={toDateId}
            type="text"
            readOnly
            disabled={disabled}
            className={classes.textField}
            value={endDate ? endDate.format('L') : ''}
            onChange={handleChange}
            onClick={(e) => !disabled && handleShowDatePicker(e, 'endDate')}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="pick end date from calendar"
                  onMouseDown={onMouseDownHandler}
                >
                  <CalendarToday />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <PickerComponent
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
          onFocusChange={onFocusChangeHandler}
          focusedInput={focusedInput}
          onDatesChange={onDateChangedHandler}
          onClose={onDateCloseHandler}
          isOutsideRange={isOutsideRange}
          numberOfMonths={2}
          onOutsideClick={handleOutsideClick}
          open={open}
          left={left}
          top={top}
        />
      </div>
    </>
  );
};

const PickerComponent: React.FC<
  DayPickerRangeControllerShape & { top: number; left: number; open: boolean }
> = ({ left, top, open, ...props }) => {
  const classes = useStyles({ top, left });
  if (!open) return <></>;
  return (
    <div className={classes.datePickerContainer}>
      <DayPickerRangeController {...props} />
    </div>
  );
};

export default DateRangeComponent;
