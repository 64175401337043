import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setShiftKeyAction, setCtrlKeyAction } from '../actions/KeyAction';

const initialShiftKeyState = {
  keyShift: false,
  keyCtrl: false,
};

export const keyReducer = reducerWithInitialState(initialShiftKeyState)
  .case(setShiftKeyAction, (state, payload) => {
    return {
      ...state,
      keyShift: payload,
    };
  })
  .case(setCtrlKeyAction, (state, payload) => {
    return {
      ...state,
      keyCtrl: payload,
    };
  });
