import { IStatus, initialStatus, doneStatus, startedStatus } from './IStatus';
import { IShiftCode } from '../models/Shift';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getShiftCodeAsyncAction } from '../actions/ShiftAction';

export interface IShiftCodeState extends IStatus {
  shiftCodes: IShiftCode[];
}

const initialShiftCodeState: IShiftCodeState = {
  ...initialStatus,
  shiftCodes: [],
};

export const shiftCodeReducer = reducerWithInitialState(initialShiftCodeState)
  .case(getShiftCodeAsyncAction.done, (state, payload) => {
    return {
      ...doneStatus,
      count: payload.result.count,
      shiftCodes: payload.result.items,
    };
  })
  .case(getShiftCodeAsyncAction.started, (state) => {
    return {
      ...state,
      ...startedStatus,
    };
  });
