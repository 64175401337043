import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import LazyContainer from './components/LazyContainer';
import ShiftComponent from './components/ShiftComponent';
import { useAuth0 } from './ReactAuth0Provider';
import MainPageRoute from './routes/MainPageRoute';
import DateRangeComponent from './components/DateRangeComponent';
import TripComponent from './components/TripComponent';

const App: React.FC = () => {
  const { loading } = useAuth0();

  return (
    <LazyContainer loading={loading}>
      <BrowserRouter>
        <Switch>
          <MainPageRoute exact path="/" component={ShiftComponent} />
          <MainPageRoute exact path="/d" component={TripComponent} />
        </Switch>
      </BrowserRouter>
    </LazyContainer>
  );
};

export default App;
