import {
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  setDateFilterAction,
  setFilterAction,
  setGroupFilterAction,
} from '../actions/FilterAction';
import { getGroupAsyncAction } from '../actions/GroupAction';
import { setShowTripAction } from '../actions/ShiftAction';
import { IGroup } from '../models/Group';
import { IGroupState } from '../states/GroupReducer';
import { IAppState } from '../store';
import DateRangeComponent from './DateRangeComponent';

const FilterContainer = styled.div<{ span: number }>`
  grid-column: span ${(props) => props.span};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300,
  },
  button: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  space: {
    flexGrow: 1,
  },
  center: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

const FilterComponent: React.FC<RouteComponentProps & { span: number }> = ({
  history,
  span,
}) => {
  const showTrips = useSelector<IAppState, boolean>(
    (state) => state.showTripInfo,
  );

  const filter = useSelector<
    IAppState,
    {
      startDate: Moment | null;
      endDate: Moment | null;
      group: IGroup[];
    }
  >(
    ({ filterState }) => ({
      endDate: filterState.end ? moment.utc(filterState.end) : null,
      startDate: filterState.start ? moment.utc(filterState.start) : null,
      group: filterState.group,
    }),
    (l, r) => _.isEqual(l, r),
  );
  const isLoadable = useSelector<IAppState, boolean>(
    (state) =>
      state.groupState.isLoaded &&
      state.holidayState.isLoaded &&
      state.shiftCodeState.isLoaded &&
      !state.shiftState.isLoaded &&
      !state.shiftState.isLoading,
  );

  const groupState = useSelector<IAppState, IGroupState>(
    (state) => state.groupState,
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOn, setIsOn] = useState(showTrips);

  const [selectedGroups] = useState<IGroup[]>(filter.group);

  const [selectedGroupNames, setSelectedGroupName] = useState<string[]>(
    filter.group.map((g) => JSON.stringify(g)),
  );

  const [startDate] = useState<Moment | null>(filter.startDate);
  const [endDate] = useState<Moment | null>(filter.endDate);

  const onSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(setShowTripAction(checked));
      setIsOn(checked);
    },
    [dispatch],
  );

  const onSelectionChangeHandler = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const v: IGroup[] = (event.target.value as string[]).map((j) =>
        JSON.parse(j),
      );
      setSelectedGroupName(event.target.value as string[]);
      dispatch(setGroupFilterAction(v));
    },
    [dispatch],
  );

  const onDateCloseHandler = useCallback(
    (final: { startDate: moment.Moment; endDate: moment.Moment }) => {
      if (final.startDate && final.endDate) {
        if (
          (filter.endDate && !filter.endDate.isSame(final.endDate, 'd')) ||
          (filter.startDate && !filter.startDate.isSame(final.startDate, 'd'))
        ) {
          dispatch(
            setDateFilterAction({
              end: final.endDate.utc().startOf('d').valueOf(),
              start: final.startDate.utc().startOf('d').valueOf(),
            }),
          );
        }
      }
    },
    [dispatch, filter.endDate, filter.startDate],
  );

  const onClearButtonClickedHandler = useCallback(() => {
    dispatch(setGroupFilterAction([]));
    setSelectedGroupName([]);
  }, [dispatch]);

  const onClearButtonMouseDownHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  useEffect(() => {
    if (!groupState.isLoaded && !groupState.isLoading) {
      dispatch(getGroupAsyncAction.started({}));
    }
  }, [dispatch, groupState.isLoaded, groupState.isLoading]);

  useEffect(() => {
    if (isLoadable) {
      dispatch(
        setFilterAction({
          start: moment.utc().startOf('M').valueOf(),
          end: moment.utc().endOf('M').valueOf(),
          group: selectedGroups,
        }),
      );
    }
  }, [dispatch, isLoadable, selectedGroups]);

  return (
    <FilterContainer span={span}>
      <div className={classes.root}>
        <FormControl>
          <FormControlLabel
            className={classes.center}
            control={
              <Switch
                color="primary"
                checked={isOn}
                onChange={onSwitchToggle}
                name="gilad"
              />
            }
            label=" Show Trip Info"
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="group-selector">Groups</InputLabel>
          <Select
            labelId="group-selector"
            id="group-item"
            multiple
            value={selectedGroupNames}
            onChange={onSelectionChangeHandler}
            input={
              <Input
                id="select-multiple-chip"
                endAdornment={
                  <InputAdornment position="end">
                    {selectedGroupNames.length !== 0 && (
                      <IconButton
                        aria-label="clear selection"
                        onClick={onClearButtonClickedHandler}
                        onMouseDown={onClearButtonMouseDownHandler}
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => (
              <div className={classes.chips}>
                {(selected as string[]).map((value) => {
                  const v: IGroup = JSON.parse(value);
                  return (
                    <Chip
                      key={v._id}
                      size="small"
                      label={v.groupName}
                      className={classes.chip}
                    />
                  );
                })}
              </div>
            )}
          >
            {groupState.groups.map((group) => (
              <MenuItem key={group._id} value={JSON.stringify(group)}>
                {group.groupName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <DateRangeComponent
            startDate={startDate}
            endDate={endDate}
            onDateFixed={onDateCloseHandler}
            fromDateId="FromDate"
            toDateId="ToDate"
          />
        </FormControl>
      </div>
    </FilterContainer>
  );
};

export default withRouter(FilterComponent);
