import {
  IStatus,
  initialStatus,
  startedStatus,
  doneStatus,
  errorStatus,
} from './IStatus';
import { ITrip, ITripCode } from '../models/Shift';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  getTripsAsyncAction,
  getTripCodesAsyncAction,
  patchTripAsyncAction,
  postTripAsyncAction,
  deleteTripAsyncAction,
} from '../actions/TripsAction';

export interface ITripState extends IStatus {
  trips: ITrip[];
}

export interface ITripCodeState extends IStatus {
  tripCodes: ITripCode[];
}

const initialTripState: ITripState = {
  ...initialStatus,
  trips: [],
};

const initialTripCodeState: ITripCodeState = {
  ...initialStatus,
  tripCodes: [],
};

export const tripStateReducer = reducerWithInitialState(initialTripState)
  .case(getTripsAsyncAction.started, (state) => {
    return {
      ...state,
      ...startedStatus,
    };
  })
  .case(getTripsAsyncAction.done, (_state, payload) => {
    return {
      ...doneStatus,
      count: payload.result.length,
      trips: payload.result,
    };
  })
  .case(getTripsAsyncAction.failed, (state, payload) => {
    return {
      ...state,
      ...errorStatus(payload.error),
    };
  })
  .case(patchTripAsyncAction.done, (state, payload) => {
    const tmp = { ...state };
    const cur = tmp.trips.findIndex((t) => t._id === payload.result._id);
    if (cur >= 0) {
      tmp.trips[cur] = payload.result;
    }
    return { ...tmp };
  })
  .case(postTripAsyncAction.done, (state, payload) => {
    return {
      ...state,
      count: (state.count || 0) + 1,
      trips: [payload.result, ...state.trips],
    };
  })
  .case(deleteTripAsyncAction.done, (state, payload) => {
    return {
      ...state,
      count: (state.count || 0) - 1,
      trips: [...state.trips.filter((t) => t._id !== payload.params)],
    };
  });

export const tripCodeReducer = reducerWithInitialState(initialTripCodeState)
  .case(getTripCodesAsyncAction.started, (state) => {
    return {
      ...state,
      ...startedStatus,
    };
  })
  .case(getTripCodesAsyncAction.done, (_state, payload) => {
    return {
      ...doneStatus,
      count: payload.result.length,
      tripCodes: payload.result,
    };
  })
  .case(getTripCodesAsyncAction.failed, (state, payload) => {
    return {
      ...state,
      ...errorStatus(payload.error),
    };
  });
