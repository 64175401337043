import { call, put, takeLatest } from 'redux-saga/effects';
import { getHolidaysAsyncAction } from '../actions/HolidayAction';
import { getHolidays } from '../services/api-service';
import { PromiseGenericType } from './PromiseGenericType';

function* getHolidaySaga() {
  const {
    error,
    data,
  }: PromiseGenericType<ReturnType<typeof getHolidays>> = yield call(
    getHolidays,
  );
  if (!error && data) {
    yield put(
      getHolidaysAsyncAction.done({
        params: {},
        result: data,
      }),
    );
  } else {
    yield put(
      getHolidaysAsyncAction.failed({
        error: error || 'Un-caught error',
        params: {},
      }),
    );
  }
}

export default function* combinedHolidaySaga() {
  yield takeLatest(getHolidaysAsyncAction.started, getHolidaySaga);
}
