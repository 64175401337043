import { Action } from 'typescript-fsa';
import {
  getShiftCodeAsyncAction,
  postShiftAsyncAction,
} from '../actions/ShiftAction';
import { setErrorAction } from '../actions/ErrorAction';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getPermissionsAsyncAction,
  getTokenAsyncAction,
} from '../actions/TokenActions';
import { getGroupAsyncAction } from '../actions/GroupAction';
import { getHolidaysAsyncAction } from '../actions/HolidayAction';
import {
  getTripsAsyncAction,
  patchTripAsyncAction,
  postTripAsyncAction,
  deleteTripAsyncAction,
} from '../actions/TripsAction';

type ErrorAction = Action<
  {
    params: {};
  } & {
    error: {};
  }
>;

function* setErrorSaga(e: ErrorAction) {
  yield put(setErrorAction(e.payload.error));
}

export default function* combinedErrorSaga() {
  yield takeLatest(
    [
      getShiftCodeAsyncAction.failed,
      getGroupAsyncAction.failed,
      getHolidaysAsyncAction.failed,
      getPermissionsAsyncAction.failed,
      getTokenAsyncAction.failed,
      postShiftAsyncAction.failed,
      getTripsAsyncAction.failed,
      patchTripAsyncAction.failed,
      postTripAsyncAction.failed,
      deleteTripAsyncAction.failed,
    ],
    setErrorSaga,
  );
}
