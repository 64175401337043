import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const HeaderBand = styled.div<{
  rowNo: number;
  colNo?: number;
  span?: number;
}>`
  grid-row: ${(props) => props.rowNo};
  grid-column: span ${(props) => props.span || 1};
`;

const ShiftHeaderBandCell = styled.div`
  text-align: left;
  font-weight: bold;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const Sticky = styled.div`
  display: inline;
  position: sticky;
  left: 0;
`;

const RowHeaderBandComponent: React.FC<{ begin: Moment; end: Moment }> = ({
  begin,
  end,
}) => {
  const [months, setMonths] = useState<
    { month: Moment; daysContain: number }[]
  >([]);
  useEffect(() => {
    const m: { month: Moment; daysContain: number }[] = [];
    for (
      let mCur = begin.clone();
      mCur.isSameOrBefore(end.clone().endOf('M'));
      mCur.add(1, 'M')
    ) {
      const tmp = mCur.clone();
      const isFullDays = tmp.clone().endOf('M').isSameOrBefore(end, 'd');
      const isFirstMonth = tmp.isSame(begin, 'M');
      const diff = isFirstMonth
        ? mCur.clone().endOf('M').diff(begin, 'd') + 1
        : isFullDays
        ? tmp.daysInMonth()
        : end.diff(tmp.clone().startOf('M'), 'd') + 1;
      m.push({ month: tmp.clone().startOf('M'), daysContain: diff });
    }
    setMonths(m);
  }, [begin, end]);
  return (
    <>
      {months.map((month) => (
        <HeaderBand
          rowNo={1}
          span={month.daysContain}
          key={`${month.month.format('YYYY-MM')}`}
        >
          <ShiftHeaderBandCell>
            <Sticky>{month.month.format('MMM, YYYY')}</Sticky>
          </ShiftHeaderBandCell>
        </HeaderBand>
      ))}
    </>
  );
};

export default RowHeaderBandComponent;
