import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import history from './utils/history';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { setShiftKeyAction } from './actions/KeyAction';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from './ReactAuth0Provider';
import config from './auth_config.json';
import { ThemeProvider } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: {
      main: '#f44336',
    },
  },
});

window.addEventListener('keydown', (e) => {
  if (e.keyCode === 16 && !store.getState().keyState.keyShift) {
    store.dispatch(setShiftKeyAction(true));
  }
});

window.addEventListener('keyup', (e) => {
  if (e.keyCode === 16 && store.getState().keyState.keyShift) {
    store.dispatch(setShiftKeyAction(false));
  }
});

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider
          domain={config.domain}
          client_id={config.clientId}
          audience={config.audience}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
