import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ITrip } from '../models/Shift';
import { IAppState } from '../store';

interface ITripCol {
  startDateOverFlow: boolean;
  endDateOverFlow: boolean;
  from: number;
  colSpan: number;
  tripCode: string;
  fromDate: Moment;
  toDate: Moment;
  destination: string;
}

interface ITripInfo {
  empId: string;
  range: {
    start: Moment;
    end: Moment;
  };
  trips: {
    code: string;
    from: Moment;
    to: Moment;
  }[];
}

const TripCellContainer = styled.div.attrs(
  ({ from, colSpan }: { from: number; colSpan: number }) => ({ from, colSpan }),
)`
  grid-row: 2;
  grid-column: ${(props) => props.from} / span ${(props) => props.colSpan};
`;

const TripCellGrid = styled.div.attrs(({ col }: { col: number }) => col)`
  grid-row: 2;
  grid-column: ${(props) => props.col};
  border-right: 1px dashed #ddd;
`;

const TripCell = styled.div.attrs(
  ({ isStart, isEnd }: { isStart: boolean; isEnd: boolean }) => ({
    isStart,
    isEnd,
  }),
)`
  text-align: center;
  border: 1px solid #121212;
  background-color: #ff9a6a61;
  font-size: 14px;
  margin: 5px 0;
  z-index: 2;
  border-top-left-radius: ${({ isStart }) => (isStart ? '0' : '9.5px')};
  border-bottom-left-radius: ${({ isStart }) => (isStart ? '0' : '9.5px')};
  border-top-right-radius: ${({ isEnd }) => (isEnd ? '0' : '9.5px')};
  border-bottom-right-radius: ${({ isEnd }) => (isEnd ? '0' : '9.5px')};
`;

const TripRowComponent: React.FC<{
  empId: string;
  start: Moment;
  end: Moment;
}> = ({ empId, start, end }) => {
  const [tripCols, setTripCols] = useState<ITripCol[]>([]);
  const trips = useSelector<IAppState, ITrip[]>(
    ({ currentShiftView }) =>
      currentShiftView.data.find((d) => d.emp.empId === empId)?.trips || [],
    (l, r) => _.isEqual(l, r),
  );

  useEffect(() => {
    const b = 1;
    const e = Math.abs(start.diff(end, 'd'));
    const tc: ITripCol[] = [];
    trips.forEach((trip) => {
      const tS = moment.utc(trip.tripDateFrom).diff(start, 'd');
      const tE = moment.utc(trip.tripDateTo).diff(start, 'd');
      const from = tS < b ? b : tS + 1;
      const end = tE > e ? e + 1 : tE + 1;
      const colSpan = end - from + 1;
      if (tS <= e && tE >= 0) {
        tc.push({
          colSpan,
          from,
          tripCode: trip.trip.tripCode,
          endDateOverFlow: tE > e,
          startDateOverFlow: tS < b - 1,
          fromDate: moment.utc(trip.tripDateFrom),
          toDate: moment.utc(trip.tripDateTo),
          destination: trip.trip.tripDestination,
        });
      }
    });
    setTripCols(tc);
  }, [end, start, trips]);

  return (
    <>
      {_.range(1, Math.abs(start.diff(end, 'd')) + 1).map((i) => {
        return <TripCellGrid key={`${empId}-${i}`} col={i} />;
      })}
      {tripCols.map((trip) => (
        <TripCellContainer
          key={`${trip.tripCode}-${trip.from}-${trip.colSpan}`}
          id={`${trip.tripCode}-${trip.from}-${trip.colSpan}`}
          from={trip.from}
          colSpan={trip.colSpan}
        >
          <Tooltip
            arrow
            title={
              <>
                <div>Destination: {trip.destination}</div>
                <div>From: {trip.fromDate.format('L')}</div>
                <div>To: {trip.toDate.format('L')}</div>
              </>
            }
          >
            <TripCell
              isEnd={trip.endDateOverFlow}
              isStart={trip.startDateOverFlow}
            >
              {trip.tripCode}
            </TripCell>
          </Tooltip>
        </TripCellContainer>
      ))}
    </>
  );
};

export default TripRowComponent;
