import _ from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRow } from '../models/Cells';
import { isMitacHoliday } from '../services/holidayChecker';
import { IAppState } from '../store';
import RowHeaderBandComponent from './RowHeaderBandComponent';

const getHeaderColor = (p: 'night' | 'day' | 'holiday') => {
  switch (p) {
    case 'day':
      return '#ffffd9';
    case 'night':
      return '#c9caf2';
    case 'holiday':
      return '#dbdbdb';
  }
};

const HeaderRow = styled.div<{
  rowNo: number;
}>`
  grid-row: ${(props) => props.rowNo};
`;

const ShiftHeaderCell = styled.div<{ isHoliday: boolean }>`
  text-align: center;
  font-weight: bold;
  border-right: 1px solid #ddd;
  background-color: ${(props) => (props.isHoliday ? 'orange' : 'white')};
`;

const RowHeaderContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr repeat(auto, 4);
  grid-auto-columns: 35px;
  border-bottom: 1px solid #ddd;
`;

const PersonCountCell = styled.div<{
  row: number;
  color: string;
}>`
  grid-row: ${(props) => props.row};
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: ${(props) => props.color};
  text-align: center;
`;

const PersonCountCellComponent: React.FC<{
  date: string;
}> = ({ date }) => {
  const availCount = useSelector<IAppState, MyProp>(
    ({ currentShiftView }) => ({
      countDay: currentShiftView.data
        .map((d) => {
          const t = d.shifts.find((s) => s.date === date);
          if (!t) return false;
          if (t.newShift) {
            return t.newShift.isDayShift;
          }
          return t.shift.isDayShift;
        })
        .filter((e) => e).length,
      countNight: currentShiftView.data
        .map((d) => {
          const t = d.shifts.find((s) => s.date === date);
          if (!t) return false;
          if (t.newShift) {
            return t.newShift.isNightShift;
          }
          return t.shift.isNightShift;
        })
        .filter((e) => e).length,
      countIdle: currentShiftView.data
        .map((d) => {
          const t = d.shifts.find((s) => s.date === date);
          if (!t) return true;
          if (t.newShift) {
            return t.newShift.isIdle;
          }
          return t.shift.isIdle;
        })
        .filter((e) => e).length,
    }),
    (l, r) => _.isEqual(l, r),
  );
  return (
    <>
      <PersonCountCell row={3} color={getHeaderColor('day')}>
        {availCount.countDay.toString()}
      </PersonCountCell>
      <PersonCountCell row={4} color={getHeaderColor('night')}>
        {availCount.countNight.toString()}
      </PersonCountCell>
      <PersonCountCell row={5} color={getHeaderColor('holiday')}>
        {availCount.countIdle.toString()}
      </PersonCountCell>
    </>
  );
};
type MyProp = {
  countIdle: number;
  countDay: number;
  countNight: number;
};

const ShiftHeaderRowComponent: React.FC<{
  rows: IRow[];
  begin: Moment;
  end: Moment;
}> = ({ rows, begin, end }) => {
  return (
    <RowHeaderContainer>
      <RowHeaderBandComponent begin={begin} end={end} />
      {rows.map((row) => {
        const mDate = moment.utc(row.date);
        return (
          <HeaderRow rowNo={2} key={row.date}>
            <ShiftHeaderCell isHoliday={isMitacHoliday(mDate)}>
              <div style={{ fontSize: '12px' }}>{mDate.format('ddd')}</div>
              <div>{mDate.date().toString()}</div>
            </ShiftHeaderCell>
            <PersonCountCellComponent date={row.date} />
          </HeaderRow>
        );
      })}
    </RowHeaderContainer>
  );
};

export default ShiftHeaderRowComponent;
