import { IStatus, initialStatus, doneStatus, startedStatus } from './IStatus';
import { IHoliday } from '../models/IHoliday';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { getHolidaysAsyncAction } from '../actions/HolidayAction';

export interface IHolidayState extends IStatus {
  holidays: IHoliday[];
}

const initialHolidayState: IHolidayState = {
  ...initialStatus,
  holidays: [],
};

export const holidaysReducer = reducerWithInitialState(initialHolidayState)
  .case(getHolidaysAsyncAction.done, (_state, { result }) => {
    return {
      ...doneStatus,
      count: result.count,
      holidays: result.items,
    };
  })
  .case(getHolidaysAsyncAction.started, (state) => {
    return {
      ...state,
      ...startedStatus,
    };
  });
