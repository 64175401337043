import React from 'react';
import { Route } from 'react-router-dom';
import MainContainer from '../containers/MainContainer';

const MainPageRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => (
        <MainContainer>
          <Component {...props} />
        </MainContainer>
      )}
    />
  );
};

export default MainPageRoute;
