import { PromiseGenericType } from './PromiseGenericType';
import { getShiftCodes } from '../services/api-service';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getShiftCodeAsyncAction } from '../actions/ShiftAction';

function* getShiftCodeSaga() {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof getShiftCodes>> = yield call(
    getShiftCodes,
  );
  if (!error && data) {
    yield put(
      getShiftCodeAsyncAction.done({
        params: {},
        result: data,
      }),
    );
  } else {
    yield put(
      getShiftCodeAsyncAction.failed({
        error: error || 'Un-caught error',
        params: {},
      }),
    );
  }
}

export default function* combinedShiftCodeSaga() {
  yield takeLatest(getShiftCodeAsyncAction.started, getShiftCodeSaga);
}
