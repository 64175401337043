import React from 'react';
import styled from 'styled-components';
import { IShift } from '../models/Shift';
import ShiftCellComponent from './ShiftCellComponent';
import { useSelector } from 'react-redux';
import { IAppState } from '../store';
import _ from 'lodash';

const ShiftRowContainer = styled.div`
  display: grid;
  position: relative;
  grid-auto-rows: 35px;
  grid-auto-columns: 35px;
  border-bottom: 1px solid #ddd;
`;

const ShiftRowComponent: React.FC<{
  empId: string;
}> = ({ empId, children }) => {
  const shifts = useSelector<IAppState, IShift[]>(
    ({ currentShiftView }) =>
      currentShiftView.data.find((d) => d.emp.empId === empId)?.shifts || [],
    (l, r) => _.isEqual(l, r),
  );
  return (
    <ShiftRowContainer>
      {shifts.map((s) => (
        <ShiftCellComponent value={s} key={`${s._id}`} />
      ))}
      {children}
    </ShiftRowContainer>
  );
};

export default ShiftRowComponent;
