import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  clearHistoryAction,
  clearSelectedShiftsAction,
  decShiftHistoryIndexAction,
  getShiftDataAction,
  incShiftHistoryIndexAction,
  resetShiftHistoryIndexAction,
  setCopiedItemsAction,
  setCurrentViewAction,
  setSelectedShiftEndAction,
  setSelectedShiftsAction,
  setSelectedShiftStartAction,
  setShiftHistoryIndexAction,
  setShowTripAction,
  setUpdatedShiftsAction,
  shiftHistoryNewAction,
  shiftHistoryPushAction,
} from '../actions/ShiftAction';
import { IShift, IShiftData, IUpdatedSelection } from '../models/Shift';
import {
  doneStatus,
  errorStatus,
  initialStatus,
  IStatus,
  startedStatus,
} from './IStatus';

const initialShiftSelectionState: { start?: IShift; end?: IShift } = {};

const initialShiftShiftDataState: IShiftDataState = {
  ...initialStatus,
  shiftData: { shiftEndDate: '', shiftStartDate: '', data: [] },
};

const initialCurrentViewState: IShiftData = {
  shiftEndDate: '',
  shiftStartDate: '',
  data: [],
};

const initialSlectedShiftState: IShift[] = [];
const initialUpdatedShiftState: IUpdatedSelection[] = [];
const initialShiftEditHistory: IShiftEditHistory = {
  history: [],
};
export interface IShiftDataState extends IStatus {
  shiftData: IShiftData;
}
export interface IShiftEditHistory {
  history: IShiftData[];
}

export const shiftEditHistoryReducer = reducerWithInitialState(
  initialShiftEditHistory,
)
  .case(shiftHistoryPushAction, (state, payload) => {
    return {
      history: [...state.history, { ...payload }],
    };
  })
  .case(clearHistoryAction, (state) => {
    return {
      history: [state.history[0]],
    };
  })
  .case(shiftHistoryNewAction, (_state, payload) => ({ history: [payload] }));

export const shiftEditHistoryIndexReducer = reducerWithInitialState(-1)
  .case(incShiftHistoryIndexAction, (state) => state + 1)
  .case(decShiftHistoryIndexAction, (state) => state - 1)
  .case(resetShiftHistoryIndexAction, () => 0)
  .case(shiftHistoryNewAction, () => 0)
  .case(setShiftHistoryIndexAction, (_state, payload) => payload);

export const showTripInfoReducer = reducerWithInitialState(false).case(
  setShowTripAction,
  (_state, payload) => payload,
);

export const copiedShiftReducer = reducerWithInitialState(
  initialSlectedShiftState,
).case(setCopiedItemsAction, (_state, payload) => {
  return [...payload];
});

export const selectedShiftsReducer = reducerWithInitialState(
  initialSlectedShiftState,
)
  .case(setSelectedShiftsAction, (_state, payload) => {
    return [...payload];
  })
  .case(clearSelectedShiftsAction, (_state, _payload) => []);

export const updatedShiftsReducer = reducerWithInitialState(
  initialUpdatedShiftState,
).case(setUpdatedShiftsAction, (_state, payload) => {
  return [...payload];
});

export const shiftSelectionReducer = reducerWithInitialState(
  initialShiftSelectionState,
)
  .case(setSelectedShiftStartAction, (state, payload) => {
    return {
      ...state,
      start: payload,
    };
  })
  .case(setSelectedShiftEndAction, (state, payload) => {
    return {
      ...state,
      end: payload,
    };
  });

export const shiftDataReducer = reducerWithInitialState(
  initialShiftShiftDataState,
)
  .case(getShiftDataAction.started, (state, _payload) => {
    return {
      ...state,
      ...startedStatus,
    };
  })
  .case(getShiftDataAction.done, (_state, payload) => {
    return {
      ...doneStatus,
      shiftData: payload.result,
    };
  })
  .case(getShiftDataAction.failed, (state, payload) => {
    return {
      ...state,
      ...errorStatus(payload.error),
    };
  });
export const currentShiftViewReducer = reducerWithInitialState(
  initialCurrentViewState,
).case(setCurrentViewAction, (_state, payload) => payload);
