import _ from 'lodash';
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  setDateFilterAction,
  setFilterAction,
  setGroupFilterAction,
} from '../actions/FilterAction';
import {
  clearHistoryAction,
  decShiftHistoryIndexAction,
  getShiftDataAction,
  GetShiftDataAction,
  incShiftHistoryIndexAction,
  onCopyAction,
  onPasteAction,
  postShiftAsyncAction,
  resetShiftHistoryIndexAction,
  SetClickedShiftAction,
  setClickedShiftAction,
  setCopiedItemsAction,
  setCurrentViewAction,
  setSelectedShiftEndAction,
  SetSelectedShiftEndAction,
  setSelectedShiftsAction,
  setSelectedShiftStartAction,
  SetSelectedShiftStartAction,
  SetShiftCodeAction,
  setShiftCodeAction,
  setShiftHistoryIndexAction,
  shiftHistoryNewAction,
  shiftHistoryPushAction,
  SetShiftAsCalendarAction,
  setShiftAsCalendarAction,
} from '../actions/ShiftAction';
import { IShift, IShiftData } from '../models/Shift';
import { getShifts, postShifts } from '../services/api-service';
import store from '../store';
import { PromiseGenericType } from './PromiseGenericType';
import { isMitacHoliday } from '../services/holidayChecker';
import {
  postTripAsyncAction,
  patchTripAsyncAction,
  deleteTripAsyncAction,
} from '../actions/TripsAction';

function* getShiftDataSaga(action: GetShiftDataAction) {
  const {
    data,
    error,
  }: PromiseGenericType<ReturnType<typeof getShifts>> = yield call(
    getShifts,
    action.payload,
  );
  if (!error && data) {
    yield put(
      getShiftDataAction.done({
        params: action.payload,
        result: data,
      }),
    );
    yield put(shiftHistoryNewAction(data));
  } else {
    yield put(
      getShiftDataAction.failed({
        error: error || 'Un-caught error',
        params: action.payload,
      }),
    );
  }
}

function* setSelectedShiftsSaga(action: SetSelectedShiftEndAction) {
  const state = store.getState();

  const shifts = _.cloneDeep(
    state.shiftEditHistory.history[state.shiftEditHistoryIndex],
  );
  if (state.selectedShiftRange.start) {
    yield put(
      setSelectedShiftsAction([
        ...selectRange(action.payload, state.selectedShiftRange.start, shifts),
      ]),
    );
  }
}

function selectRange(s1: IShift, s2: IShift, shifts: IShiftData) {
  let endDate = moment.utc(s1.date);
  let startDate = moment.utc(s2.date);
  const selection: IShift[] = [];
  if (startDate.isAfter(endDate)) {
    const t = startDate.clone();
    startDate = endDate.clone();
    endDate = t.clone();
  }
  let s = shifts.data.findIndex((x) => x.shifts.some((y) => y._id === s1._id));
  let e = shifts.data.findIndex((x) => x.shifts.some((y) => y._id === s2._id));
  if (s > e) {
    const tmp = s;
    s = e;
    e = tmp;
  }

  for (let i = s; i <= e; i++) {
    const row = shifts.data[i];
    for (let j = startDate.clone(); j.isSameOrBefore(endDate); j.add(1, 'd')) {
      const f = row.shifts.find((o) => j.isSame(moment.utc(o.date), 'd'));
      if (f) {
        selection.push(f);
      }
    }
  }
  return selection;
}

function* resetSelectedShiftsSaga(a: SetSelectedShiftStartAction) {
  const s = a.payload;
  yield put(setSelectedShiftsAction([s]));
}

function* setUpdatedShiftSaga(a: SetShiftCodeAction) {
  const code = a.payload;
  const state = store.getState();
  const shifts = _.cloneDeep(
    state.shiftEditHistory.history[state.shiftEditHistoryIndex],
  );
  const selectedShifts = state.selectedShifts;
  const shiftCodes = store.getState().shiftCodeState.shiftCodes;

  if (!selectedShifts.length) {
    return;
  }
  shifts.data.forEach((s) => {
    if (selectedShifts.some((u) => s.shifts.some((x) => x._id === u._id))) {
      s.shifts
        .filter((x) => selectedShifts.some((y) => y._id === x._id))
        .forEach((x) => {
          if (x.shift?.shiftCode === code) {
            x.newShift = undefined;
            x.isUpdated = false;
          } else {
            x.newShift = shiftCodes.find((sc) => sc.shiftCode === code);
            x.isUpdated = true;
          }
        });
    }
  });

  yield put(shiftHistoryPushAction({ ...shifts }));
}

function* setSelectionSaga(e: SetClickedShiftAction) {
  const isShift = store.getState().keyState.keyShift;
  if (isShift) {
    yield put(setSelectedShiftEndAction(e.payload));
  } else {
    yield put(setSelectedShiftStartAction(e.payload));
  }
}

function* copySelectedShiftSaga() {
  const { selectedShifts } = store.getState();
  if (selectedShifts.length) {
    yield put(setCopiedItemsAction([...selectedShifts]));
  }
}

function* pasteShiftSaga() {
  const state = store.getState();
  const { copiedShifts, selectedShiftRange } = state;
  if (!copiedShifts.length || !selectedShiftRange.start) {
    return;
  }
  const shiftData = _.cloneDeep(
    state.shiftEditHistory.history[state.shiftEditHistoryIndex],
  );
  const indexRow = shiftData.data.findIndex((e) =>
    e.shifts.some((s) => s._id === selectedShiftRange.start?._id),
  );
  const indexCol = moment
    .utc(selectedShiftRange.start?.date)
    .diff(shiftData.shiftStartDate, 'd');
  const minDate = copiedShifts
    .map((a) => moment.utc(a.date).valueOf())
    .reduce((a, b) => Math.min(a, b));
  const diffCol =
    indexCol - moment.utc(minDate).diff(shiftData.shiftStartDate, 'd');
  const minRow = shiftData.data.findIndex((emp) =>
    emp.shifts.some((s) => copiedShifts.some((c) => s._id === c._id)),
  );
  const pastedShifts: IShift[] = [];
  const diffRow = indexRow - minRow;
  copiedShifts.forEach((cs) => {
    const curRow = shiftData.data.findIndex((e) =>
      e.shifts.some((s) => s._id === cs._id),
    );
    const row = curRow + diffRow;
    if (row < shiftData.data.length) {
      const date = moment.utc(cs.date).add(diffCol, 'd');
      const toUpdate = shiftData.data[row].shifts.find((s) =>
        moment.utc(s.date).isSame(date, 'd'),
      );
      if (
        toUpdate &&
        (cs.newShift
          ? toUpdate.shift.shiftCode !== cs.newShift?.shiftCode
          : toUpdate.shift.shiftCode !== cs.shift.shiftCode)
      ) {
        toUpdate.isUpdated = true;
        toUpdate.newShift = cs.newShift || cs.shift || undefined;
        pastedShifts.push(toUpdate);
      }
    }
  });
  yield put(shiftHistoryPushAction({ ...shiftData }));
}

function* setShiftHistoryIndexSaga() {
  yield put(
    setShiftHistoryIndexAction(
      store.getState().shiftEditHistory.history.length - 1,
    ),
  );
}

function* resetShiftHistoryIndexSaga() {
  yield put(resetShiftHistoryIndexAction());
}

function* setCurrentViewSaga() {
  const { shiftEditHistory, shiftEditHistoryIndex, shiftState } = _.cloneDeep(
    store.getState(),
  );
  if (shiftEditHistoryIndex < 0) {
    yield put(setCurrentViewAction({ ...shiftState.shiftData }));
  } else {
    yield put(
      setCurrentViewAction({
        ...shiftEditHistory.history[shiftEditHistoryIndex],
      }),
    );
  }
}

function* postShiftDataSaga() {
  const { currentShiftView } = store.getState();
  const updatedShifts = currentShiftView.data
    .flatMap((d) => d.shifts.filter((s) => s.isUpdated))
    .map((s) => ({
      oldShift: s.shift.shiftCode,
      shiftId: s._id,
      empId: s.emp,
      shiftCodeId: s.newShift?._id || '',
      date: s.date,
    }));
  const r = {
    updatedShifts: updatedShifts
      .filter((s) => s.oldShift !== '-')
      .map((s) => ({
        shiftCodeId: s.shiftCodeId,
        shiftId: s.shiftId,
      })),
    newShifts: updatedShifts
      .filter((s) => s.oldShift === '-')
      .map((s) => ({
        empId: s.empId,
        shiftCodeId: s.shiftCodeId,
        date: s.date,
      })),
  };
  const {
    error,
  }: PromiseGenericType<ReturnType<typeof postShifts>> = yield call(
    postShifts,
    r,
  );
  if (!error) {
    yield put(postShiftAsyncAction.done({ params: {}, result: {} }));
  } else {
    yield put(
      postShiftAsyncAction.failed({
        error: error || 'Un-caught error',
        params: {},
      }),
    );
  }
}

function* reloadShiftDataSaga() {
  const filter = store.getState().filterState;
  yield put(getShiftDataAction.started(filter));
}

function* setFilterSaga() {
  const filter = store.getState().filterState;
  yield put(getShiftDataAction.started(filter));
}

function* setShiftAsCalendarSaga(action: SetShiftAsCalendarAction) {
  const shiftToEdit = _.cloneDeep(store.getState().currentShiftView);
  const shiftCodes = _.cloneDeep(store.getState().shiftCodeState.shiftCodes);
  const dayShift = shiftCodes.find((c) => c.shiftCode === 'D');
  const holidayShift = shiftCodes.find((c) => c.shiftCode === 'H');
  const empId = action.payload;
  const dataToEdit = shiftToEdit.data.find((d) => d.emp.empId === empId);
  if (!dataToEdit || !dayShift || !holidayShift) return;
  dataToEdit.shifts.forEach((s) => {
    const isHoliday = isMitacHoliday(moment.utc(s.date));
    if (isHoliday) {
      if (s.shift._id !== holidayShift._id) {
        s.newShift = { ...holidayShift };
        s.isUpdated = true;
      }
    } else {
      if (s.shift._id !== dayShift._id) {
        s.newShift = { ...dayShift };
        s.isUpdated = true;
      }
    }
  });
  yield put(shiftHistoryPushAction({ ...shiftToEdit }));
}

export default function* combinedShiftSaga() {
  yield takeLatest(getShiftDataAction.started, getShiftDataSaga);
  yield takeLatest(setClickedShiftAction, setSelectionSaga);
  yield takeLatest(setSelectedShiftEndAction, setSelectedShiftsSaga);
  yield takeLatest(setSelectedShiftStartAction, resetSelectedShiftsSaga);
  yield takeLatest(setShiftCodeAction, setUpdatedShiftSaga);
  yield takeLatest(onCopyAction, copySelectedShiftSaga);
  yield takeLatest(onPasteAction, pasteShiftSaga);
  yield takeLatest(shiftHistoryPushAction, setShiftHistoryIndexSaga);
  yield takeLatest(clearHistoryAction, resetShiftHistoryIndexSaga);
  yield takeLatest(
    [
      resetShiftHistoryIndexAction,
      incShiftHistoryIndexAction,
      shiftHistoryNewAction,
      decShiftHistoryIndexAction,
      setShiftHistoryIndexAction,
    ],
    setCurrentViewSaga,
  );
  yield takeLatest(postShiftAsyncAction.started, postShiftDataSaga);
  yield takeLatest(
    [
      postShiftAsyncAction.done,
      postTripAsyncAction.done,
      patchTripAsyncAction.done,
      deleteTripAsyncAction.done,
    ],
    reloadShiftDataSaga,
  );
  yield takeLatest(setShiftAsCalendarAction, setShiftAsCalendarSaga);
  yield takeLatest(
    [setFilterAction, setDateFilterAction, setGroupFilterAction],
    setFilterSaga,
  );
}
